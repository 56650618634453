import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const findImageCaption = (img) => {
  const figure = img.closest("figure");

  if (figure) {
    const figCaption = figure.querySelector("figcaption");

    if (figCaption) {
      return figCaption.textContent;
    }
  }

  return img.getAttribute("alt");
};

const Image = (props) => {
  const {
    onKeyDown,
    src,
    caption,
    onClose,
    onNext,
    onPrevious,
    hasNext,
    hasPrevious,
  } = props;

  return (
    <div className="modal is-active" onKeyDown={onKeyDown}>
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title"></p>
          <button
            className="delete"
            aria-label="close"
            onClick={onClose}
          ></button>
        </header>
        <section className="modal-card-body">
          <img
            style={{ display: "block", margin: "0 auto" }}
            src={src}
            onClick={onNext}
          />
          <nav className="level is-mobile mt-2">
            <div className="level-left">
              <div className="level-item has-text-centered">
                <button
                  className="button is-small level-item"
                  onClick={onPrevious}
                  disabled={!hasPrevious}
                >
                  <span className="icon is-small">
                    <FontAwesomeIcon icon={faArrowLeft} size="xs" />
                  </span>
                  <span>Previous</span>
                </button>
              </div>
            </div>
            <p className="px-4 is-size-7 is-family-secondary">{caption}</p>
            <div className="level-right">
              <p className="level-item has-text-centered">
                <button
                  className="button is-small level-item"
                  onClick={onNext}
                  disabled={!hasNext}
                >
                  <span>Next</span>
                  <span className="icon is-small">
                    <FontAwesomeIcon icon={faArrowRight} size="xs" />
                  </span>
                </button>
              </p>
            </div>
          </nav>
        </section>
      </div>
    </div>
  );
};

class AutoGallery extends React.Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();

    this.state = {
      currentIndex: null,
      images: [],
      totalImages: 0,
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKey, false);

    const images = this.ref.current.querySelectorAll(
      'img:not([aria-hidden="true"])'
    );

    console.log(`AutoGallery found ${images.length} images`);

    this.setState({
      images,
      totalImages: images.length,
    });
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKey, false);
  }

  currentImage = () => {
    const image = this.state.images[this.state.currentIndex];

    if (!image) {
      return null;
    }

    return {
      src: image.src,
      caption: findImageCaption(image),
    };
  };

  handleClick = (e) => {
    if (e.target.tagName !== "IMG") {
      return;
    }

    const index = [].findIndex.call(this.state.images, (image) => {
      return image.src === e.target.src;
    });

    this.setState({ currentIndex: index });
  };

  handleKey = (e) => {
    switch (e.key) {
      case "ArrowRight":
      case "ArrowDown":
        e.preventDefault();
        return this.page("right");
      case "ArrowLeft":
      case "ArrowUp":
        e.preventDefault();
        return this.page("left");
      case "Escape":
        this.handleClose();
      default:
      // Do nothing.
    }
  };

  handleClose = () => {
    this.setState({ currentIndex: null });
  };

  page = (direction) => {
    const { totalImages, currentIndex } = this.state;

    const nextIndex =
      direction === "right"
        ? Math.min(currentIndex + 1, totalImages - 1)
        : Math.max(currentIndex - 1, 0);

    this.setState({
      currentIndex: nextIndex,
    });
  };

  hasNext = () => {
    const { totalImages, currentIndex } = this.state;
    return currentIndex < totalImages - 1;
  };

  hasPrevious = () => {
    const { currentIndex } = this.state;
    return currentIndex > 0;
  };

  render() {
    const currentImage = this.currentImage();

    return (
      <div>
        {currentImage && (
          <Image
            src={currentImage.src}
            caption={currentImage.caption}
            onKeyDown={this.handleKey}
            onClose={this.handleClose}
            onNext={() => this.page("right")}
            onPrevious={() => this.page("left")}
            hasNext={this.hasNext()}
            hasPrevious={this.hasPrevious()}
          />
        )}
        <div onClick={this.handleClick} ref={this.ref}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default AutoGallery;
