import React, { useRef } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import AutoGallery from "../components/AutoGallery";

const TileImage = ({
  src,
  width = "100%",
  height = 240,
  backgroundPosition = "center",
  caption = null,
}) => {
  const imgRef = useRef(null);

  return (
    <div
      style={{
        backgroundImage: `url(${src})`,
        backgroundSize: "cover",
        backgroundPosition,
        height,
        width,
      }}
      onClick={() => {
        imgRef.current.click();
      }}
    >
      <figure>
        <img ref={imgRef} src={src} style={{ display: "none" }} />
        {caption && (
          <figcaption style={{ display: "none" }}>{caption}</figcaption>
        )}
      </figure>
    </div>
  );
};

export const StoryPageTemplate = ({}) => (
  <React.Fragment>
    <section className="section">
      <div className="container">
        <h3 className="subtitle is-2">How we met</h3>
        <p className="mb-4">
          We met at our Austin apartment complex when Becca moved in in 2012.
          Here are some of the earliest pictures of us from before we were
          dating!
        </p>
      </div>
      <div
        className="tile is-ancestor mt-5"
        style={{ maxWidth: 1100, margin: "0 auto" }}
      >
        <div className="tile is-parent is-4">
          <TileImage
            src="/img/escalaeaster2.jpg"
            height={312}
            caption="Enjoying Easter dinner with friends at Escala, the apartment complex where Becca and Aaron first met."
          />
        </div>
        <div className="tile is-parent is-3">
          <TileImage
            src="/img/escalaeaster.jpg"
            height={312}
            caption="These two people have no idea what is in store :)"
          />
        </div>
        <div className="tile is-parent is-5">
          <TileImage
            src="/img/2012_camping.jpg"
            height={312}
            caption="Our first camping trip together. What would become the first of many!"
          />
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container">
        <h3 className="subtitle is-2">9 years of adventuring together</h3>
        <p className="mb-4">
          Since then, we’ve gotten to share so many adventures, from learning to
          scuba dive and backpack together, to taking care of our cat Oslo.
        </p>
      </div>
      <div
        className="tile is-ancestor mt-5"
        style={{ maxWidth: 1100, margin: "0 auto" }}
      >
        <div className="tile is-6 is-vertical">
          <div className="tile ">
            <div className="tile is-parent is-6">
              <TileImage
                src="/img/IMG_20190608_171906.jpg"
                backgroundPosition="unset"
                caption="Hiking and camping in Great Sand Dunes National Park."
              />
            </div>
            <div className="tile is-parent is-6">
              <TileImage
                src="/img/2017_backpacking_smokies.jpg"
                caption="Hiking in Smoky Mountain National Park with Becca's parents to enjoy the eclipse."
              />
            </div>
          </div>
          <div className="tile">
            <div className="tile is-parent is-5">
              <TileImage
                src="/img/2015_half_ironman.jpg"
                backgroundPosition="-17px 0"
                caption="100% confident and not scared before a half ironman in Mexico."
              />
            </div>
            <div className="tile is-parent is-7">
              <TileImage
                src="/img/2018_tomatoes.jpg"
                caption="Picking tomatoes with Aaron's mom at Johnson's Backyard Garden near Austin."
              />
            </div>
          </div>
        </div>
        <div className="tile is-6">
          <div className="tile is-vertical is-8">
            <div className="tile is-parent">
              <TileImage
                src="/img/2019_el_chalten.jpg"
                height={300}
                caption="Hiking in El Chalten with a view of Fitz Roy."
              />
            </div>
            <div className="tile is-parent">
              <TileImage
                src="/img/italy_biking.JPG"
                height={180}
                caption="Biking through Italian wine country."
              />
            </div>
          </div>
          <div className="tile is-vertical is-4">
            <div className="tile is-parent">
              <TileImage
                src="/img/2018_scuba.jpg"
                caption="Scuba diving in Okinawa, Japan."
              />
            </div>
            <div className="tile is-parent">
              <TileImage src="/img/oslo.jpg" caption="A vicious predator." />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section mb-5">
      <div className="container">
        <h3 className="subtitle is-2">Our engagement</h3>
        <p className="mb-4">
          Aaron proposed on a spectacular hike in Grand Teton National Park. A
          moose was the first to congratulate us!
        </p>
      </div>
      <div
        className="tile is-ancestor mt-5"
        style={{ maxWidth: 1100, margin: "0 auto" }}
      >
        <div className="tile is-parent is-3">
          <TileImage
            src="/img/2020_teton_trail.jpg"
            height={377}
            caption="The beginning of our hike through Cascade Canyon in Grand Teton National Park."
          />
        </div>
        <div className="tile is-3 is-vertical">
          <div className="tile is-parent">
            <TileImage
              src="/img/2020_teton_inspiration_point.jpg"
              height={234}
              caption="A view of Jenny Lake."
            />
          </div>
          <div className="tile is-parent">
            <TileImage
              src="/img/2020_teton_moose_wide.jpg"
              height={119}
              caption={`He's saying "Congratulations on the engagement!"`}
            />
          </div>
        </div>
        <div className="tile is-parent is-3">
          <TileImage
            src="/img/2020_teton_ring.jpg"
            height={377}
            caption="She said yes!"
          />
        </div>
        <div className="tile is-parent is-3">
          <TileImage src="/img/2020_teton_kiss.jpg" height={377} caption="💏" />
        </div>
      </div>
    </section>
  </React.Fragment>
);

const StoryPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout title={frontmatter.title}>
      <AutoGallery>
        <StoryPageTemplate />
      </AutoGallery>
    </Layout>
  );
};

StoryPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default StoryPage;

export const storyPageQuery = graphql`
  query StoryPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
      }
    }
  }
`;
